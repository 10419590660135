* {
    padding: 0;
    margin: 0;
}

html, body {
    height: 100%;
}

#root {
    height: 100%;
}

.packageNotificationContainer {
  position: absolute;
  top: -20px;
  left: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
}

.packageNotificationContainer > div {
  margin-right: 5px;
}

.expiredPackage {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #ff1212;
  box-shadow: 0px 0px 5px #fff;
  border: 1px solid #900;
}

.valuablePackage {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 16px;
  height: 16px;
  text-shadow: 0px 0px 1px #e0e0e0;
  background-color: #0db8b2;
  border-radius: 50%;
  border: 2px solid #06928B;
  z-index: 1;
}

.tooltip-message::before {
  border-left: 5px solid transparent;
  border-top: 5px solid #ffe4b5;
  border-right: 5px solid transparent;
  height: 0px;
  width: 0px;
  content:'';
  top: -2px;
  left: 6px;
}

.tooltip-message::after {
  content: attr(data-message);
  top: -25px; left: -5px;
  background: #0db8b2;
  color: white;
  font-weight: 500;
  letter-spacing: 0.02cm;
  border-radius: 4px;
  padding: 2px 6px;
  white-space: nowrap;
}

.tooltip-message::after, .tooltip-message::before {
  position: absolute;
  opacity: 0;
  transition: 300ms opacity linear;
}

.tooltip-message:hover::after, .tooltip-message:hover::before {
  opacity: 1;
}
